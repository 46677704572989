import React from 'react';
import { Box, Grid, IconButton, Link, styled, Typography } from '@mui/material';
import { Email, LocationCity, Phone, WhatsApp } from '@mui/icons-material';

const CurvedBoxTopLeft = styled(Box)(({ theme }) => ({
    backgroundColor: '#3364ae',
    // minHeight: '60vh',
    width: '100%',
    maxWidth: '100%',
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
    borderTopLeftRadius: '300px',
}));



const Footer = () => {
    return (
        <CurvedBoxTopLeft>
            <Box component="footer" sx={{ color: 'white', padding: '8em 0 4em', display: 'flex', flexDirection: 'column', gap: 8 }}>
                <Box sx={{ display: 'flex', flexDirection: {lg: 'row', xs: 'column', sm: 'column'}, alignItems: {lg: 'start', xs: 'center', sm: 'center'}, justifyContent: 'center', flexWrap: 'wrap', gap: 4 }}>
                    {/* First Column */}
                    <Box sx={{ textAlign: 'center', flex: '1 1 35%', marginTop: '-2em' }}>
                        <Box sx={{
                            borderRadius: '100%',
                            width: '150px',
                            height: '150px',
                            margin: '0 auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // padding: '1em',
                            backgroundColor: 'white',
                            marginBottom: '1em'
                        }}>
                        <Link href="#hero">
                        <Box component="img" src="/assets/images/logo.png" alt="Company Logo" sx={{ height: '70px', objectFit:'contain' }} />
                        </Link>
                        </Box>
                        <Typography variant="body1" sx={{ width: '60%', margin: '0 auto', textAlign: 'justify' }} >
                            Nous sommes une équipe passionnée évoluant dans le domaine de l'informatique. Notre expertise couvre un large éventail de technologies de l'information, et nous sommes déterminés à fournir des solutions innovantes pour répondre aux besoins de nos clients.
                        </Typography>
                    </Box>

                    {/* Second Column */}
                    <Box sx={{ textAlign: {lg: 'left', xs: 'center', sm: 'center'}, flex: '1 1 auto', display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant="h6" sx={{ marginBottom: '1em', fontWeight: '900' }}>Navigation</Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1
                        }}>
                            <Link href="#hero" color="inherit" sx={{ display: 'block', marginBottom: '0.5em', textDecoration: 'none' }}>Accueil</Link>
                            <Link href="#services" color="inherit" sx={{ display: 'block', marginBottom: '0.5em', textDecoration: 'none' }}>Services</Link>
                            <Link href="#portfolio" color="inherit" sx={{ display: 'block', marginBottom: '0.5em', textDecoration: 'none' }}>Réalisations</Link>
                            <Link href="#about" color="inherit" sx={{ display: 'block', marginBottom: '0.5em', textDecoration: 'none' }}>A propos</Link>
                            <Link href="#contact" color="inherit" sx={{ display: 'block', marginBottom: '0.5em', textDecoration: 'none' }}>Contactez nous</Link>
                        </Box>
                    </Box>

                    {/* Third Column */}
                    <Box sx={{ textAlign: {lg: 'left', xs: 'center', sm: 'center'}, flex: '1 1 auto ', display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant="h6" sx={{ marginBottom: '1em', fontWeight: '900' }}>Adresse</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: {lg:'start', xs: 'center', sm: 'center'} }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'white' }} component={'a'}
                                href="mailto:contact@univ-soft.com"
                                target="_blank"
                                rel="noopener noreferrer">
                                <IconButton sx={{ color: 'white' }}>
                                    <Email />
                                </IconButton>
                                <Typography variant="body1" sx={{ marginLeft: '0.5em' }}>contact@univ-soft.com</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'white' }} component={'a'}
                                href="https://wa.me/237696458382?text=Hello%20there!"
                                target="_blank"
                                rel="noopener noreferrer">
                                <IconButton sx={{ color: 'white' }}>
                                    <WhatsApp />
                                </IconButton>
                                <Typography variant="body1" sx={{ marginLeft: '0.5em' }}>+237 696 458 382</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '' }}>
                                <IconButton sx={{ color: 'white' }}>
                                    <LocationCity />
                                </IconButton>
                                <Typography variant="body1" sx={{ marginLeft: '0.5em' }}>Sable Bonamoussadi à côté de la station Total</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box textAlign='center'>
                    <Typography sx={{ color: 'white' }}>&copy; 2024 All Rights <u>Reserved By Univsoft</u></Typography>
                </Box>
            </Box>

        </CurvedBoxTopLeft>
    )
}

export default Footer