// src/components/ServiceList.tsx
import React from 'react';
import ServiceCard from '../ServiceCard/service.card.component';
import { Grid } from '@mui/material';
import { Service } from '../ServiceCard/service.card.component';
import { Home, Build, Code, DesignServices, Support, IntegrationInstructions, SettingsInputComponent, School, AutoAwesome, Task, ManageAccounts } from '@mui/icons-material';

const services: Service[] = [
  { link: '/services/development', icon: <Code sx={{fontSize: 'inherit'}} />, title: "Développement d'applications web et mobiles", description: "Nous sommes experts dans le développement d'applications web et mobiles destinées à transformer et optimiser la gestion de votre entreprise." },
  { link: '/services/training', icon: <School sx={{fontSize: 'inherit'}}/>, title: 'Formation', description: "Notre entreprise propose des cours de formation spécialement conçus pour les professionnels souhaitant améliorer leurs compétences et progresser dans leur carrière. Nos formations couvrent divers domaines et sont adaptées aux besoins spécifiques de chaque participant." },
  { link: '/services/integration', icon: <IntegrationInstructions sx={{fontSize: 'inherit'}}/>, title: 'Intégration De Système', description: "Nous optimisons la gestion de vos systèmes grâce à notre expertise de premier plan en intégration. Notre équipe spécialisée excelle dans le développement de solutions d’intégration personnalisées, conçues pour maximiser l'efficacité de vos opérations et améliorer les interactions entre vos différentes plateformes." },
  { link: '/services/consulting', icon: <SettingsInputComponent sx={{fontSize: 'inherit'}}/>, title: "Conseil en Architecture d’entreprise et Processus", description: "Nous vous accompagnons dans la définition et la mise en œuvre de l’architecture d’entreprise, une étape cruciale pour assurer une intégration cohérente et efficace des solutions dans votre organisation. Une architecture bien conçue optimise les processus et garantit que chaque composant soutient vos objectifs stratégiques." },
  { link: '/services/data', icon: <AutoAwesome sx={{fontSize: 'inherit'}}/>, title: "data et intelligence artificielle", description: "Notre solution intègre l'expertise des data avec les avancées de l'Intelligence Artificielle (IA) pour transformer vos données en atouts stratégiques. Grâce à une plateforme analytique sophistiquée, vous avez la capacité de créer et d' explorer des tableaux de bord détaillés, facilitant ainsi des prises de décision basées sur des données précises et pertinentes." },
  { link: '/services/', icon: <ManageAccounts sx={{fontSize: 'inherit'}}/>, title: "Logiciel de gestion d'entreprise", description: "Que vous dirigiez une agence de courtage, un point de vente, un établissement scolaire ou un institut de beauté, nous avons des solutions numérique parfaitement adaptée à vos besoins" },  
];

const ServiceList: React.FC = () => {
  return (
    <Grid container spacing={2}>
      {services.map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index} sx={{marginBottom: "2em",}}>
          <ServiceCard service={service} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ServiceList;
