import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import About from '../../components/about/about.component';
import ContactForm from '../../components/contact/ContactForm/contact.form.component';
import Hero from '../../components/home/hero/hero';
import ProjectList from '../../components/portfolio/projectList/projectList.component';
import ServiceCards from '../../components/services/ServiceList/serviceList.component';
import ValueCard from '../../components/values/ValueCard/value.card.component';
import ValueList from '../../components/values/ValueList/valueList.component';

export const CurvedBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#3364ae',
  height: '85vh',
  width: '100%',
  maxWidth: '100%',
  clipPath: 'polygon(0 0, 100% 0, 100% calc(100% - 100px), 100% 100%, 0 100%)',
  borderBottomRightRadius: '300px',
}));

export const CurvedBoxBothSides = styled(Box)(({ theme }) => ({
  backgroundColor: '#3364ae',
  minHeight: '100vh',
  width: '100%',
  maxWidth: '100%',
  clipPath: 'polygon(0 0, 100% 0, 100% calc(100% - 100px), calc(100% - 100px) 100%, 0 100%)',
  borderBottomRightRadius: '250px',
  borderTopLeftRadius: '250px',
}));

export const CurvedBoxLeftSide = styled(Box)(({ theme }) => ({
  backgroundColor: '#3364ae',
  minHeight: '85vh',
  width: '100%',
  maxWidth: '100%',
  clipPath: 'polygon(0 calc(100% - 100px), 0 0, calc(100% - 100px) 0, 100% 0, 100% 100%, 0 100%)',
  borderTopLeftRadius: '300px',
}));

const Home: React.FC = () => {
  return (
    <Box sx={{maxWidth: '100%'}}>

      {/* Home */}
      
      <CurvedBox>
        <Box id='hero' sx={{
          minHeight: {lg: '100%', xs: '100%', sm: '100%'},
          maxWidth: '100%',
          display: 'flex',
          alignItems: 'center',
          padding: {lg:'0.5em 5em', xs: '0.5em 3em'},
        }} >
          <Hero />
        </Box>
        {/* Your content here */}
      </CurvedBox>

      {/* Services */}
      
      <Box id='services' sx={{
        minHeight: '100vh',
        maxWidth: '100%',
        padding: { lg: '8em 5em', xs: '8em 1em' },
        display: 'flex',
        flexDirection: 'column',
        gap: 8

      }}>

        <Box>
          <Typography variant='h1' sx={{ textTransform: 'capitalize', textAlign: 'center', fontWeight: '900',
            fontSize: {
              sm: "2em",
              xs: "2em"
            }
           }}>
            nos services
          </Typography>
          <Typography variant='body1' sx={{ textTransform: 'none', textAlign: 'center' }}>
            contactez-nous pour les services suivants
          </Typography>
        </Box>
        <ServiceCards />
      </Box>


      {/* About us */}
      <CurvedBoxBothSides>
        <Box id='about' sx={{
          minHeight: '100vh',
          maxWidth: '100%',
          padding: {lg:'0.5em 5em', xs: '0.5em 1.5em', sm: '0.5em 1.5em'},
        }} >
          <About />
        </Box>
      </CurvedBoxBothSides>

      {/* Our values */}
      <Box id='' sx={{
        minHeight: '100vh',
        maxWidth: '100%',
        padding:  { lg: '5em 5em', xs: '8em 1em' },
        display: 'flex',
        flexDirection: 'column',
        gap: 10
      }}>
        <Box>
          <Typography variant='h1' sx={{ textTransform: 'capitalize', textAlign: 'center', fontWeight: '900', 
            fontSize: {
               sm: "2em",
              xs: "2em"
            }
           }}>
            nos valeurs
          </Typography>
        </Box>
        <ValueList />
      </Box>

      {/* Portfolio */}
      <CurvedBoxBothSides>
        <Box id='portfolio' sx={{
          minHeight: '100vh',
          maxWidth: '100%',
          padding:  { lg: '8em 5em', xs: '8em 1em' },
          display: 'flex',
          flexDirection: 'column',
          gap: 10
        }} >
          <Box>
            <Typography variant='h1' sx={{ textTransform: 'capitalize', textAlign: 'center', fontWeight: '900', color: 'white',
                 fontSize: {
                  sm: "2em",
                  xs: "2em"
                }
             }}>
              nos realisations
            </Typography>
            <Typography variant='body1' sx={{ textTransform: 'none', textAlign: 'center', color: 'white' }}>
              Découvrez ce que nous faisons pour nos précieux clients
            </Typography>
          </Box>
          <ProjectList />
        </Box>
      </CurvedBoxBothSides>

      {/* Contact */}
      <Box id='contact' sx={{
      
        minHeight: '100vh',
        maxWidth: '100%',
        padding: {lg: '8em 5em', xs: '5em 1.5em', sm: '2em 1.5em'},
        display: 'flex',
        flexDirection: 'column',
        gap: 6
      }}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant='h1' sx={{ textTransform: 'capitalize', textAlign: 'center', fontWeight: '900', fontSize: {
                  sm: "2em",
                  xs: "2em"
                } }}>
            contactez nous
          </Typography>
          <Typography variant='body1' sx={{ textTransform: 'none', textAlign: 'center', color: 'black' }}>
            {/* Découvrez ce que nous faisons pour nos précieux clients */}
          </Typography>
        </Box>
        <ContactForm />
      </Box>
    </Box>
  );
}

export default Home;
