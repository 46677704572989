import React, { useState } from 'react';
import { Alert, Box, Button, CircularProgress, Snackbar, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Send } from '@mui/icons-material';
import emailjs from '@emailjs/browser';
import { useForm, Controller } from 'react-hook-form';


const CenteredBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    // minHeight: '100vh',
});

const StyledTextField = styled(TextField)({
    '& .MuiInputLabel-root': {
        color: 'grey',
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: 'grey',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'grey',
        },
        '&:hover fieldset': {
            borderColor: 'grey',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'grey',
        },
    },
});


const ContactForm: React.FC = () => {

    const { handleSubmit, control, reset } = useForm();
    const [loading, setLoading] = useState(false);
    
    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);

    const onSubmit = (data: any) => {
        setLoading(true);
        emailjs.send('service_j2er3re', 'template_dk5om1e', data, 'BNPiEYFOLV7rYvLnG')
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
                
                setLoading(false);
                setSuccessOpen(true);
                reset();
            }, (error) => {
                console.error('Failed to send email.', error);
                setLoading(false);
                setErrorOpen(true);
            });
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessOpen(false);
        setErrorOpen(false);
    };


    return (
        <CenteredBox>
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    width: {lg: '70%', xs: '100%', sm: '100%'},
                    //   maxWidth: '400px',
                    padding: {lg: 5, xs: 3, sm: 3},
                    margin: 0,
                    backgroundColor: 'white',
                    borderRadius: 1,
                    boxShadow: 3,
                }}
            >
                <Box sx={{
                    height: '10%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '1em'
                }}>
                    <Box
                        component="img"
                        src='/assets/images/logo.png' // Assuming project.logo is the URL of the image
                        alt='univsoft logo'
                        sx={{
                            width: {lg:'20%', xs: '40%', sm: '40%'},
                            height: '20%',
                            borderRadius: '50%',
                            objectFit: 'cover'
                        }}
                    />
                </Box>
                {/* <StyledTextField label="Full Name" variant="outlined" fullWidth required /> */}
                <Controller
                    name="from_email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} label="Email" variant="outlined" fullWidth required />}
                />

                <Controller
                    name="from_phone"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} label="Téléphone" variant="outlined" fullWidth required />}
                />
                {/* <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} label="Phone Number" variant="outlined" fullWidth />}
                /> */}
                <Controller
                    name="message"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} label="Message" variant="outlined" fullWidth required multiline rows={4} />}
                />
                <Button
                    sx={{
                        marginLeft: "auto",
                        padding: ".8em 1.2em",
                        color: "#3364ae",
                        fontWeight: "900",
                        minWidth: "20%",
                        textTransform: "capitalize",
                        backgroundColor: "white",
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.9)'
                        },
                    }}
                    variant='contained'
                    type="submit"
                    endIcon={loading ? <CircularProgress size={24} /> : <Send />}
                    disabled={loading}
                >
                    Envoyer
                </Button>
                <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Email envoyé avec succès!
                </Alert>
            </Snackbar>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Échec de l'envoi de l'email. Veuillez réessayer.
                </Alert>
            </Snackbar>
            </Box>
        </CenteredBox>
    );
};

export default ContactForm;
