import { CalendarToday, HourglassBottomOutlined, Send } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import SendIcon from '@mui/icons-material/Send';
import { AppBar, Toolbar, Box, Tabs, Tab, Button, useTheme, Typography, styled, List, ListItem, ListItemText, Drawer, IconButton, Menu, ListItemButton } from '@mui/material';
import React, { useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { CurvedBox } from '../../features/home/home.feature';


interface NavbarProps {
    activeSection: string;
    handleScroll: (section: string) => void;
}




const Navbar = () => {

    const [activeSection, setActiveSection] = useState<string>('');
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const menuItems = [
        { text: 'Accueil', link: 'hero', },
        { text: 'Services', link: 'services' },
        { text: 'Réalisations', link: 'portfolio' },
        { text: 'A propos', link: 'about' },
        { text: 'Contactez-nous', link: 'contact' },
    ];

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <List >
                {menuItems.map((item, index) => (
                    <ListItem key={index} sx={{ textDecoration: 'none', color: 'black', textTransform:"uppercase" }} disablePadding>
                        <ListItemButton onClick={() => handleScroll(item.link)} sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item.text} sx={{}} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );



    const handleScroll = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        setActiveSection(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Box>
            {/* Navbar */}
            <AppBar position='sticky' elevation={0} sx={{
                display: "flex",
                justifyContent: 'start',
                alignItems: "center",
                padding: { lg: "0px 4.5em", sm: "0.6em 0.2em", xs: "0.6em 0.2em" },
                backgroundColor: "#3364ae",

                // background: 'linear-gradient(to right, white 1%, #003366 100%)',
            }}>

                {/* desktop version */}
                <Toolbar sx={{ width: "100%", display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px' }}>
                    {/* logo */}
                    <Box sx={{
                            borderRadius: '5px',
                            width: '120px',
                            // height: '50px',
                            margin: '0 auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '0.4em 0.8em',
                            backgroundColor: 'white',
                            // marginBottom: '1em'
                        }}>
                    <Link to="#hero" onClick={() => handleScroll('hero')} style={{ textDecoration: 'none' }}>
                        <Box component="img" src="/assets/images/logo.png" alt="UnivSoft Logo" sx={{ height: '30px' }} />
                    </Link>
                    </Box>


                    {/* nav menu */}
                    <Box sx={{ display: { xs: 'none', md: 'none', lg: 'flex' }, flexGrow: 1, justifyContent: 'center' }}>
                        {/* nav menu */}
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <NavLink
                                to="#hero"
                                end
                                style={{
                                    backgroundColor: activeSection === 'hero' ? 'white' : 'transparent',
                                    color: activeSection === 'hero' ? '#3364ae' : 'white',
                                    textDecoration: 'none',
                                    textTransform: 'uppercase',
                                    // fontWeight: '900',
                                    padding: '0.5em 1em',
                                    borderRadius: '4px',
                                }}
                                onClick={() => handleScroll('hero')}
                            >
                                Accueil
                            </NavLink>
                            <NavLink
                                to="/#services"
                                style={{
                                    backgroundColor: activeSection === 'services' ? 'white' : 'transparent',
                                    color: activeSection === 'services' ? '#3364ae' : 'white',
                                    textDecoration: 'none',
                                    textTransform: 'uppercase',
                                    // fontWeight: '900',
                                    padding: '0.5em 1em',
                                    borderRadius: '4px',
                                }}
                                onClick={() => handleScroll('services')}
                            >
                                Services
                            </NavLink>
                            <NavLink
                                to="/#portfolio"
                                style={{
                                    backgroundColor: activeSection === 'portfolio' ? 'white' : 'transparent',
                                    color: activeSection === 'portfolio' ? '#3364ae' : 'white',
                                    textDecoration: 'none',
                                    textTransform: 'uppercase',
                                    // fontWeight: '900',
                                    padding: '0.5em 1em',
                                    borderRadius: '4px',
                                }}
                                onClick={() => handleScroll('portfolio')}
                            >
                                Réalisations
                            </NavLink>
                            <NavLink
                                to="/#about"
                                style={{
                                    backgroundColor: activeSection === 'about' ? 'white' : 'transparent',
                                    color: activeSection === 'about' ? '#3364ae' : 'white',
                                    textDecoration: 'none',
                                    textTransform: 'uppercase',
                                    // fontWeight: '900',
                                    padding: '0.5em 1em',
                                    borderRadius: '4px',
                                }}
                                onClick={() => handleScroll('about')}
                            >
                                A propos
                            </NavLink>
                        </Box>
                    </Box>

                    {/* Menu icon on the right */}
                    <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                        sx={{ display: { lg: 'none', xs: 'flex', md: 'flex' } }}
                    >
                        <MenuIcon sx={{ fontSize: '2em', color: "white" }} />
                    </IconButton>
                    <Drawer
                        anchor="right"
                        open={drawerOpen}
                        onClose={toggleDrawer(false)}


                    >
                        <Box sx={{ padding: '5em 0' }}>
                            {DrawerList}

                        </Box>
                    </Drawer>


                    {/* cta */}
                    <Button
                        onClick={() => handleScroll('contact')}
                        sx={{
                            display: { xs: 'none', md: 'none', lg: 'flex' },
                            marginLeft: "auto",
                            padding: ".4em 1.2em",
                            color: "#3364ae",
                            // fontWeight: "900",
                            // minWidth: "20%",
                            // boxShadow:"none",
                            textTransform: "uppercase",
                            backgroundColor: "white",
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.9)'
                            },
                        }} variant='contained' endIcon={<Send />} >Contactez-nous</Button>


                </Toolbar>

                {/* mobile version */}
            </AppBar>
        </Box>
    )
}

export default Navbar



