import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import Carousel from 'react-material-ui-carousel';

interface CarouselItemProps {
  image: string;
  header: string;
  subheader: string;
  buttonText: string;
}
const handleScroll = (sectionId: string) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
};

const CarouselItem: React.FC<CarouselItemProps> = ({ image, header, subheader, buttonText }) => (
  <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }} alignItems="center" justifyContent="center" height="100%">
    <Box flex={1}>
      <img src={image} alt={header} style={{ maxWidth: '100%', maxHeight: '75vh' }} />
    </Box>
    <Box flex={1} padding={2} width={{ xs: '100%', lg: '40%' }} display='flex' flexDirection='column' gap={4}>
      <Typography variant="h2" sx={{ lineHeight: '1em', fontWeight: '900', width: '80%', margin: '0 auto' }}>{header}</Typography>
      <Typography variant="subtitle1" gutterBottom sx={{ width: '80%', margin: '0 auto' }}>{subheader}</Typography>
      <Button variant="contained" color="primary"
        onClick={() => handleScroll('contact')}
        sx={{
          padding: ".8em 1.2em",
          color: "#3364ae",
          fontWeight: "900",
          fontSize: "1em",
          margin: '0 auto',
          textTransform: "capitalize",
          backgroundColor: "white",
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)'
          },
        }}>{buttonText}</Button>
    </Box>
  </Box>
)




const items: CarouselItemProps[] = [
  {
    image: "/assets/images/slider_image-2.png",
    header: "Augmentez votre chiffre d'affaires en créant un site web performant",
    subheader: "Profitez d'un développement de qualité au service de vos objectifs et générez un retour sur investissement",
    buttonText: "Contactez-nous"
  },
  {
    image: "/assets/images/slider_image-1.png",
    header: "Nous travaillons pour le succès total de votre projet",
    subheader: "Quels que soient vos besoins, toute notre équipe s'emploie à donner vie à votre projet",
    buttonText: "Contactez-nous"
  },
  
  // {
  //   image: '/assets/images/h2-banner1.png',
  //   header: "Nous travaillons pour le succès total de votre projet",
  //   subheader: "Quels que soit vos besoins, toute notre équipe s'emploie à donner vie à votre projet",
  //   buttonText: 'contactez-nous'
  // }
];

const Hero: React.FC = () => (
  <Box sx={{
    height: {lg: '100%', xs: "100vh", sm: '100vh',md: '100%'},
    width: '100%', // Ensure the Box takes the full width
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white'
  }}>
    {/* Desktop */}
    <Box sx={{ width: '100%', maxWidth: '100%', display: { lg: 'inline', xs: 'none', md: 'none' } }}> {/* Ensure the Carousel takes the full width */}
      <Carousel
        navButtonsAlwaysInvisible
        indicators
        autoPlay
        interval={60000} //  interval
        indicatorIconButtonProps={{
          style: {
            padding: '10px',    // Adjust the size of the indicators
            color: 'grey'       // Color of unselected indicators
          }
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: 'white'      // Color of the active indicator
          }
        }}
        indicatorContainerProps={{
          style: {
            marginTop: '20px',  // Adjust the position of the indicators
            textAlign: 'center'
          }
        }}
      >
        {items.map((item, index) => (
          <CarouselItem key={index} {...item} />
        ))}
      </Carousel>
    </Box>

    {/* Carousel for mobile and tablet */}
    <Box sx={{ width: '100%', maxWidth: '100%', display: { lg: 'none', xs: 'block', sm: 'block', md: 'block' } }}>
      <Carousel
        navButtonsAlwaysInvisible
        indicators
        autoPlay
        interval={60000} //  interval
        indicatorIconButtonProps={{
          style: {
            padding: '10px',    // Adjust the size of the indicators
            color: 'grey'       // Color of unselected indicators
          }
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: 'white'      // Color of the active indicator
          }
        }}
        indicatorContainerProps={{
          style: {
            marginTop: '20px',  // Adjust the position of the indicators
            textAlign: 'center'
          }
        }}
      >
        {items.map((item, index) => (
          <Grid container direction="column" spacing={2} key={index}>
            <Grid item>
              <img src={item.image} alt={item.header} style={{ width: '100%' }} />
            </Grid>
            <Grid item>
              <Typography variant="h6" sx={{ lineHeight: "1em", fontWeight: "900", }} >{item.header}</Typography>
              <Typography variant="body1" sx={{
                margin: "1em auto"
              }}>{item.subheader}</Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary"
                onClick={() => handleScroll('contact')}
                sx={{
                  padding: ".8em 1.2em",
                  color: "#3364ae",
                  fontWeight: "900",
                  fontSize: "1em",
                  margin: '0 auto',
                  textTransform: "capitalize",
                  backgroundColor: "white",
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.9)'
                  },
                }}>{item.buttonText}</Button>
            </Grid>
            <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                {/* Custom indicators */}
                {/* {items.map((_, dotIndex) => (
                  <Box
                    key={dotIndex}
                    sx={{
                      width: 8,
                      height: 8,
                      borderRadius: '50%',
                      backgroundColor: index === dotIndex ? 'white' : 'grey',
                      mx: 0.5,
                    }}
                  />
                ))} */}
              </Box>
            </Grid>
          </Grid>
        ))}
      </Carousel>
    </Box>

  </Box>
);

export default Hero;
